import React, { useEffect } from "react";
import { useParams } from "react-router";
import { useApi } from "../../../api/apiContext";
import SchoolCounselorLayout from "../../../layouts/SchoolCounselorLayout/SchoolCounselorLayout";
import {
  MemberTitle,
  InfoRow,
  InfoSection,
  InfoSectionTitle,
  InfoSectionText,
  InlineTitle,
  SectionTitle,
  Container,
  DischargeGridContainer,
  StudentDetailsCollapsableSection,
  GridContainer,
  ROIBox,
  ROIIcon,
  ROIText,
  ROIHeadline,
  ROIBody,
  InsuranceSection,
  InsuranceSectionBody,
  TableContainer,
  CareStatusSection,
  InsuranceAndMultipleReferrals,
  MultipleReferralsSection,
  MultipleReferralsBody,
} from "./elements";
import { Session, SessionStatus } from "../../../api/queries/sessions";
import CollapsibleSection from "../../../components/CollapsibleSection/CollapsibleSection";
import InfoCard from "../../../components/Cards/InfoCard/InfoCard";
import { OrganizationMemberV1 } from "../../../api/queries/organizationMembers";
import { DateTime } from "luxon";
import Icon from "../../../components/Icon/Icon";
import TextLink from "../../../components/TextLink/TextLink";
import InsuranceStatusBox from "./InsuranceStatusBox/InsuranceStatusBox";
import CareStatusBox from "./CareStatusBox/CareStatusBox";
import SessionsTable from "./SessionsTable";
import { Referral } from "../../../api/queries/referrals";
import ReferralHistoryBox from "./ReferralHistoryBox/ReferralHistoryBox";
import OrganizationMembers from "../../../lib/models/organizationMembers";
import { useHasOrganizationFeature } from "../../../hooks/useHasOrganizationFeature";
import { trackPage } from "client/amplitudeHelper";

export type SyntheticSession = Omit<Session, "status" | "startTime"> & {
  status: SessionStatus | "passed" | "unknown";
  startTime: DateTime | null;
};
export type StudentDetailsPageWithDataProps = {
  orgMember: OrganizationMemberV1;
  sessions: (Session | SyntheticSession)[];
  referrals: Referral[];
  appointmentsAvailable: boolean;
};
export const StudentDetailsPageWithData: React.FC<StudentDetailsPageWithDataProps> = ({
  orgMember,
  sessions,
  referrals,
  appointmentsAvailable,
}) => {
  let careProviderInfo = (
    <InfoSectionText small subtle>
      To be assigned later
    </InfoSectionText>
  );

  if (orgMember.careProvider?.fullName) {
    careProviderInfo = (
      <>
        <InfoSectionText primary>
          {orgMember.careProvider.fullName}
        </InfoSectionText>
        <InfoSectionText>{orgMember.careProvider.email}</InfoSectionText>
      </>
    );
  }
  let referredByInfo = (
    <InfoSectionText small subtle>
      not on record
    </InfoSectionText>
  );
  const { firstName, lastName, email } = orgMember.referredBy ?? {};

  if (firstName || lastName || email) {
    referredByInfo = (
      <>
        {(firstName || lastName) && (
          <InfoSectionText primary>
            {[firstName, lastName].join(" ").trim()}
          </InfoSectionText>
        )}
        {email && (
          <InfoSectionText>{orgMember.referredBy?.email}</InfoSectionText>
        )}
      </>
    );
  }

  const welcomePacket = orgMember.welcomePacket
    ? {
        ...orgMember.welcomePacket,
        dueDate: orgMember.welcomePacket?.dueDate
          ? DateTime.fromSeconds(
              orgMember.welcomePacket.dueDate
            ).toLocaleString(DateTime.DATE_FULL)
          : "",
        packetCompletedAt: orgMember.welcomePacket?.packetCompletedAt
          ? DateTime.fromSeconds(
              orgMember.welcomePacket.packetCompletedAt
            ).toLocaleString(DateTime.DATE_FULL)
          : "",
        packetSignedAt: orgMember.welcomePacket?.packetSignedAt
          ? DateTime.fromSeconds(
              orgMember.welcomePacket.packetSignedAt
            ).toLocaleString(DateTime.DATE_FULL)
          : "",
      }
    : null;

  const backLink = "/school/referred-students-list";
  const journeyStatus = OrganizationMembers.getJourneyStatus(orgMember);
  return (
    <SchoolCounselorLayout
      backNavLink={backLink}
      backNavText="Back to referrals"
    >
      <Container>
        <MemberTitle>
          {orgMember.firstName} {orgMember.lastName}
        </MemberTitle>
        <InfoRow>
          <InfoSection>
            <InfoSectionTitle>School info:</InfoSectionTitle>
            <InfoSectionText primary>{orgMember.schoolName}</InfoSectionText>
            <p>Grade: {orgMember.gradeLevel}</p>
          </InfoSection>
          <InfoSection>
            <InfoSectionTitle>Assigned counselor:</InfoSectionTitle>
            {careProviderInfo}
          </InfoSection>
          <InfoSection>
            <InfoSectionTitle>English Second Language:</InfoSectionTitle>
            <InfoSectionText primary>No</InfoSectionText>
          </InfoSection>
          <InfoSection>
            <InfoSectionTitle>Referred by:</InfoSectionTitle>
            {referredByInfo}
          </InfoSection>
        </InfoRow>
        <InsuranceAndMultipleReferrals>
          {welcomePacket && (
            <InfoSection>
              <InfoSectionTitle>Welcome Packet:</InfoSectionTitle>
              {welcomePacket.packetComplete && (
                <>
                  <InfoSectionText primary>
                    Completed{" "}
                    {welcomePacket.packetCompletedAt && (
                      <>on {welcomePacket.packetCompletedAt}</>
                    )}
                  </InfoSectionText>
                  <InfoSectionText>
                    {welcomePacket.insuranceStatus &&
                      (welcomePacket.insuranceRequired ||
                        welcomePacket.insuranceStatus !== "Not Requested") && (
                        <>
                          <InlineTitle>Insurance:</InlineTitle>{" "}
                          {welcomePacket.insuranceStatus}
                        </>
                      )}
                  </InfoSectionText>
                </>
              )}
              {!welcomePacket.packetComplete && (
                <>
                  <InfoSectionText primary>
                    {welcomePacket.welcomePacketUrl ? (
                      <a href={welcomePacket.welcomePacketUrl}>
                        Onboarding Forms <Icon name="link" />
                      </a>
                    ) : (
                      `Due by ${welcomePacket.dueDate}`
                    )}
                  </InfoSectionText>
                  <InfoSectionText>
                    <InlineTitle>Status:</InlineTitle> {welcomePacket.status}
                  </InfoSectionText>
                  <InfoSectionText>
                    <InlineTitle>Consents completed?</InlineTitle>{" "}
                    {welcomePacket.packetSignedAt ? "Yes" : "No"}
                  </InfoSectionText>
                  <InfoSectionText>
                    <InlineTitle>Intake Questionnaire Completed?</InlineTitle>{" "}
                    {welcomePacket.intakeStatus}
                  </InfoSectionText>
                  {welcomePacket.insuranceStatus &&
                    (welcomePacket.insuranceRequired ||
                      welcomePacket.insuranceStatus !== "Not Requested") && (
                      <InfoSectionText>
                        <InlineTitle>Insurance Provided?</InlineTitle>{" "}
                        {welcomePacket.insuranceStatus}
                      </InfoSectionText>
                    )}
                  {welcomePacket.packetSignedAt && (
                    <InfoSectionText>
                      Packet signed on {welcomePacket.packetSignedAt}
                    </InfoSectionText>
                  )}
                </>
              )}
            </InfoSection>
          )}
          {!welcomePacket ? (
            <InsuranceSection>
              <SectionTitle>Insurance</SectionTitle>
              <InsuranceSectionBody>
                <InsuranceStatusBox organizationMember={orgMember} />
              </InsuranceSectionBody>
            </InsuranceSection>
          ) : null}
          {referrals.length <= 1 ? null : (
            <MultipleReferralsSection>
              <SectionTitle>Referral History</SectionTitle>
              <MultipleReferralsBody>
                <ReferralHistoryBox referrals={referrals} />
              </MultipleReferralsBody>
            </MultipleReferralsSection>
          )}
        </InsuranceAndMultipleReferrals>
        <CareStatusSection>
          <SectionTitle>Care Status</SectionTitle>
          <CareStatusBox status={journeyStatus ?? "Pending"} />
        </CareStatusSection>
        {orgMember.missingRoi ? (
          <ROIBox>
            <ROIIcon>
              <Icon name="exclamationCircle" />
            </ROIIcon>
            <ROIText>
              <ROIHeadline>
                Details cannot be displayed - this student does not have a
                completed Release of Information form.
              </ROIHeadline>
              <ROIBody>
                Please have the student fill out the following Release of
                Information form to proceed:{" "}
                <TextLink href="https://www.surveymonkey.com/r/daybreakROI">
                  https://www.surveymonkey.com/r/daybreakROI
                </TextLink>
              </ROIBody>
            </ROIText>
          </ROIBox>
        ) : null}
        {orgMember.discharge && !orgMember.missingRoi && (
          <>
            <SectionTitle>Discharge Summary</SectionTitle>
            <DischargeGridContainer>
              <InfoCard title="Treatment goals">
                {orgMember.discharge.schoolTreatmentGoals}
              </InfoCard>
              <InfoCard title="Information to share with the school">
                {orgMember.discharge.reportToSchool}
              </InfoCard>
              <InfoCard title="School related treatment goals">
                {orgMember.discharge.progressOnTreatmentGoals}
              </InfoCard>
              <InfoCard title="Clinical Recommendation">
                {orgMember.discharge.clinicalRecommendation}
              </InfoCard>
            </DischargeGridContainer>
          </>
        )}
        {!orgMember.missingRoi && (
          <>
            <SectionTitle>Appointments</SectionTitle>
            <TableContainer>
              {appointmentsAvailable ? (
                <SessionsTable sessions={sessions} />
              ) : (
                <> Appointments are temporarily unavailable.</>
              )}
            </TableContainer>
          </>
        )}
        {orgMember.intake && (
          <StudentDetailsCollapsableSection>
            <CollapsibleSection isOpen title="Intake results">
              <GridContainer>
                <InfoCard title="Primary needs">
                  {orgMember.intake.primaryNeed}
                </InfoCard>
                <InfoCard title="Secondary needs">
                  {orgMember.intake.additionalNeeds}
                </InfoCard>
                <InfoCard title="Treatment goals">
                  {orgMember.intake.presentingProblem}
                </InfoCard>
              </GridContainer>
            </CollapsibleSection>
          </StudentDetailsCollapsableSection>
        )}
        {orgMember.intake ? (
          <StudentDetailsCollapsableSection>
            <CollapsibleSection isOpen title="Intake request details">
              <GridContainer>
                <InfoCard title="Academic and Behavioral School Issues">
                  <p>{orgMember.intake.academicBehaviorAndSchoolIssues}</p>
                </InfoCard>
                <InfoCard title="Problem Areas">
                  <p>{orgMember.intake.counselingGoals}</p>
                </InfoCard>
              </GridContainer>
            </CollapsibleSection>
          </StudentDetailsCollapsableSection>
        ) : null}
      </Container>
    </SchoolCounselorLayout>
  );
};

export type StudentDetailsPageProps = {};

const statusString = (
  isUpcoming: boolean,
  startTime: DateTime | null | undefined
) => {
  if (isUpcoming) {
    return "upcoming";
  } else if (startTime === null || startTime === undefined) {
    return "unscheduled";
  } else if (DateTime.now() > startTime) {
    return "passed";
  } else {
    return "unknown";
  }
};

const StudentDetailsPage: React.FC<StudentDetailsPageProps> = () => {
  const api = useApi();
  const { orgMemberId } = useParams<{ orgMemberId: string }>();
  const { active: appointmentsAvailable } = useHasOrganizationFeature(
    "show_appointments_in_ssd"
  );
  const {
    data: { data: memberData } = {},
    isLoading: isLoadingMember,
  } = api.useGetOrganizationMember({
    externalId: orgMemberId,
  });

  useEffect(() => {
    trackPage("SCD:StudentDetailsPage", {
      organizationMemberId: orgMemberId,
    });
  }, [orgMemberId]);

  const {
    data: { data: sessionsData } = {},
    isLoading: isLoadingSessions,
  } = api.useGetSessions({
    userId: memberData?.externalUserId,
    options: {
      enabled: !!(memberData && memberData.externalUserId),
    },
  });

  // TODO: Change this to get the actual referrals for just this student
  const { data: { data: referrals } = {} } = api.useGetReferrals({
    sort: ["-referred_at"],
    page: 1,
    perPage: 10,
    filter: {
      referral_completed: true,
      organization_member_external_id: orgMemberId,
    },
  });

  if (isLoadingMember || isLoadingSessions || !memberData || !referrals) {
    return <SchoolCounselorLayout loading />;
  }

  const combinedSessions: (Session | SyntheticSession)[] = [
    ...(sessionsData || []),
  ];
  // Hack: if the user has an upcoming intake, insert that as an upcoming
  // synthetic appointment.
  // Hack:
  // When a student is first referred and has their intake scheduled, there are
  // two problems:
  //
  // 1. We draw session data from theranest, and intake appointments aren't put
  // into theranest until after they happen.  So upcoming intakes will never
  // show up in the usual output of the sessions endpoint.
  // 2. The student will have no attached User record.  This sucks because we
  // pull sessions by joining
  // organization_member->user->theranest_client->appointments, so even *after*
  // the intake appointment happens, the intake appointment *still* won't show
  // up in the usual output of the sessions endpoint.
  //
  // So: here's a little hack.  If we have an *Intake* record, but no results
  // from the sessions endpoint, then we're in one of the above situations.  In
  // that case, since we actually *do* have the time the intake appointment is
  // going to be, we can just insert that time as a synthetic appointment.
  if (memberData.intake?.startTime && combinedSessions.length <= 0) {
    const intake = memberData.intake;
    let isUpcoming = false;
    let startTime = null;
    // Technically, 0 would be Epoch start, but if we
    // get that back, probably nil got serialized to 0.
    if (intake.startTime && intake.startTime > 0) {
      startTime = DateTime.fromSeconds(intake.startTime);
      isUpcoming = DateTime.now() < startTime;
    }
    const status = statusString(isUpcoming, startTime);
    combinedSessions?.unshift({
      startTime: startTime,
      status: status,
      serviceTypes: [],
      types: ["Intake"],
      createdAt: 0,
      endTime: 0,
      counselors: [],
      zoomLink: "",
    });
  }

  return (
    <StudentDetailsPageWithData
      orgMember={memberData}
      sessions={combinedSessions}
      referrals={referrals}
      appointmentsAvailable={appointmentsAvailable}
    />
  );
};

export default StudentDetailsPage;
