import React, { useState } from "react";
import styled from "styled-components/macro";
import { themeColor } from "../../../app/theme";
import OrangeButton from "../../buttons/OrangeButton/OrangeButton";
import BaseModal, { BaseModalProps } from "../BaseModal/BaseModal";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  max-width: 480px;
`;
const Header = styled.div`
  font-weight: 600;
  font-size: 20px;
  color: black;
`;
const Body = styled.div`
  margin-top: 4px;
  color: ${themeColor("darkGrayText7")};
`;
const Actions = styled.div`
  display: flex;
  gap: 12px;
  justify-content: flex-end;
  margin-top: 30px;
`;
export const useConfirmParentScheduleModal = ({
  onClose = () => {},
}: {
  onClose: () => void;
}) => {
  const [modalState, setModalState] = useState<{
    showing: boolean;
    onConfirm?: () => void;
  }>({
    showing: false,
    onConfirm: undefined,
  });

  return {
    onClose,
    showing: modalState.showing,
    onConfirm: modalState.onConfirm,
    showModal: (onConfirm: () => void) =>
      setModalState({ onConfirm, showing: true }),
    hideModal: () =>
      setModalState((prevState) => ({ ...prevState, showing: false })),
  };
};

type ModalContext = ReturnType<typeof useConfirmParentScheduleModal>;
type ConfirmParentScheduleModalInsertProps = {
  modalContext: ModalContext;
  onClose?: () => void;
};
export const ConfirmParentScheduleModalInsert: React.FC<ConfirmParentScheduleModalInsertProps> = ({
  modalContext,
  onClose = () => {},
}) => {
  if (!modalContext.showing) return null;

  return (
    <ConfirmParentScheduleModal
      closeModal={() => {
        modalContext.hideModal();
        modalContext.onClose();
        onClose();
      }}
      onConfirm={modalContext.onConfirm ?? (() => {})}
    />
  );
};

export type ConfirmParentScheduleModalProps = {
  onConfirm: () => void;
} & BaseModalProps;

export const ConfirmParentSchedule: React.FC<ConfirmParentScheduleModalProps> = ({
  ...baseModalProps
}) => (
  <Container>
    <Header>Send parent onboarding forms</Header>
    <Body>
      Daybreak Health will email the student's parent with a link to complete
      onboarding forms which need to be completed for the student to access
      care. You can find a link to the forms on the student's details page after
      submitting this request for care.
    </Body>
    <Actions>
      <OrangeButton variant="secondary" onClick={baseModalProps.closeModal}>
        Cancel
      </OrangeButton>
      <OrangeButton onClick={baseModalProps.onConfirm}>Confirm</OrangeButton>
    </Actions>
  </Container>
);

export const ConfirmParentScheduleModal: React.FC<ConfirmParentScheduleModalProps> = ({
  ...baseModalProps
}) => (
  <BaseModal {...baseModalProps}>
    <ConfirmParentSchedule {...baseModalProps} />
  </BaseModal>
);

export default ConfirmParentScheduleModal;
