import { FormikProps, useFormik } from "formik";
import React from "react";
import TealButton from "../../../components/buttons/TealButton/TealButton";
import CheckboxField from "../../../components/Forms/CheckboxField/CheckboxField";
import RadioButtonGroup from "../../../components/Forms/RadioButtonGroup/RadioButtonGroup";
import TextAreaField from "../../../components/Forms/TextAreaField/TextAreaField";
import TextField from "../../../components/Forms/TextField/TextField";
import TextLink from "../../../components/TextLink/TextLink";
import * as Yup from "yup";
import {
  PartialReferralPostArguments,
  PreferredLanguage,
  usePostPartialReferral,
} from "../../../api/queries/partialReferrals";
import { Organization } from "../../../api/queries/organizations";
import { useHistory } from "react-router-dom";
import toast from "react-hot-toast";
import analytics from "../../../lib/analytics";
import { isDaybreakErrorV4, RequestError } from "../../../api/apiClient";
import { OrganizationMemberV1 } from "../../../api/queries/organizationMembers";
import {
  ConfirmParentScheduleModalInsert,
  useConfirmParentScheduleModal,
} from "../../../components/Modals/ConfirmParentScheduleModal/ConfirmParentScheduleModal";
import SelectField from "../../../components/Forms/SelectField/SelectField";
import Tooltip from "../../../components/Tooltip/Tooltip";
import Icon from "../../../components/Icon/Icon";
import { languages } from "../../../constants";
import {
  ErrorText,
  FormGroup,
  Form,
  FormGroupHeader,
  FormRowGrid,
  FieldAndError,
  FormRow,
  InsuranceTooltipAndField,
  InsuranceTooltip,
  InsuranceTooltipContents,
  YesNoQuestion,
  Errors,
  Buttons,
  Info,
} from "./elements";

// TODO: move this to the query file
type GuardianKind = "mother" | "father" | "guardian";
type YesNo = "yes" | "no";

const testDefaultValues = {
  schedulingMethod: "" as "staff_scheduled" | "parent_scheduled",
  patientPersonalEmail: "student@example.com",
  patientPhoneNumber: "555-555-5555",
  patientPreferredName: "Ted",
  guardianKind: "mother" as GuardianKind,
  guardianFirstName: "Sam",
  guardianLastName: "Smith",
  guardianPhone: "123-456-7890",
  guardianEmail: "guardian@example.com",
  guardianPreferredLanguage: "english" as PreferredLanguage,
  guardianPreferredLanguageOther: "",
  staffReferrerPhoneNumber: "456-789-0123",
  attendanceIssues: "no" as YesNo,
  guardianVerbalConsent: true,
  guardianOkToContact: "yes" as YesNo,
  academicBehaviorAndSchoolIssues: "Straight-A student",
  counselingGoals: "Lower anxiety",
  specialConsiderations: "",
  hasNoInsurance: false,
};
const defaultValues = {
  schedulingMethod: "" as "staff_scheduled" | "parent_scheduled",
  patientPersonalEmail: "",
  patientPhoneNumber: "",
  patientPreferredName: "",
  guardianKind: "" as GuardianKind,
  guardianFirstName: "",
  guardianLastName: "",
  guardianPhone: "",
  guardianEmail: "",
  guardianPreferredLanguage: "english" as PreferredLanguage,
  guardianPreferredLanguageOther: "",
  staffReferrerPhoneNumber: "",
  attendanceIssues: "" as YesNo,
  guardianVerbalConsent: false,
  guardianOkToContact: "" as YesNo,
  academicBehaviorAndSchoolIssues: "",
  counselingGoals: "",
  specialConsiderations: "",
  hasNoInsurance: false,
};
type FormValues = typeof defaultValues;

type FieldErrorProps = {
  name: keyof FormValues;
  formik: FormikProps<FormValues>;
};
const FieldError: React.FC<FieldErrorProps> = ({ name, formik }) => {
  const error = formik.errors[name];
  const touched = formik.touched[name];
  if (!error || !touched) {
    return null;
  }
  return <ErrorText>{error}</ErrorText>;
};

const languageOptions = {
  ...languages,
  prefer_not_to_answer: "Prefer not to answer",
  other: "Other (please specify)",
};
const validationSchema = Yup.object().shape({
  patientPersonalEmail: Yup.string()
    .required("Student email is required.")
    .email("Student email must be a valid email."),
  patientPhoneNumber: Yup.string().required(
    "Student phone number is required."
  ),
  patientPreferredName: Yup.string(),
  guardianKind: Yup.string().required("Parent kind is required."),
  guardianFirstName: Yup.string().required("Parent first name is required."),
  guardianLastName: Yup.string().required("Parent last name is required."),
  guardianPhone: Yup.string().required("Parent phone number is required."),
  guardianEmail: Yup.string()
    .email("Parent email must be a valid email.")
    .required("Parent email is required."),
  guardianPreferredLanguage: Yup.string()
    .oneOf(Object.keys(languageOptions))
    .required("Please select the guardian's preferred language."),
  guardianPreferredLanguageOther: Yup.string().when(
    "guardianPreferredLanguage",
    {
      is: "other",
      then: (yup) =>
        yup.required("Please specify the guardian's preferred language."),
    }
  ),
  staffReferrerPhoneNumber: Yup.string().required(
    "Referring staff phone number is required."
  ),
  attendanceIssues: Yup.string().required("Attendance issues is required."),
  guardianVerbalConsent: Yup.boolean(),
  guardianOkToContact: Yup.string().required(
    "Parent / Caregiver contact permission (or lack thereof) is required."
  ),
  academicBehaviorAndSchoolIssues: Yup.string().required(
    "Academic & Behavioral issues are required."
  ),
  counselingGoals: Yup.string().required("Problem areas are required."),
  specialConsiderations: Yup.string(),
});

const extractApiErrors = (error: RequestError | undefined) => {
  if (!error) {
    return undefined;
  }
  if (!isDaybreakErrorV4(error) || error.errorType !== "data_not_acceptable") {
    return "An unknown error occured.";
  }
  const errors = [];
  for (const key in error.errorData) {
    if (Array.isArray(error.errorData[key])) {
      error.errorData[key] = error.errorData[key].join(", ");
    }
    const humanKey = key.replaceAll(/([A-Z])/g, (l) => " " + l.toLowerCase());
    errors.push(`Error processing ${humanKey}: ${error.errorData[key]}`);
  }
  return errors.join(". ");
};

export type PartialReferralFormProps = {
  createPartialReferral: ReturnType<typeof usePostPartialReferral>["mutate"];
  organizationMember: OrganizationMemberV1;
  myOrg: Organization;
  creationError?: RequestError;
  collectInsuranceInfo: boolean;
};
export const PartialReferralForm: React.FC<PartialReferralFormProps> = ({
  createPartialReferral,
  organizationMember,
  myOrg,
  creationError,
  collectInsuranceInfo,
}) => {
  const history = useHistory();
  const confirmParentScheduleModalContext = useConfirmParentScheduleModal({
    onClose: () => {
      formik.setSubmitting(false);
    },
  });
  const saveReferral = (
    valuesToPost: Parameters<typeof createPartialReferral>[0]
  ) => {
    createPartialReferral(valuesToPost, {
      onSuccess: (data) => {
        if (valuesToPost.schedulingMethod === "parent_scheduled") {
          toast.success(
            () => (
              <div>
                <p>Successfully referred {organizationMember.firstName}!</p>
                <TextLink href={`/school/students/${organizationMember.id}`}>
                  Click here for more details
                </TextLink>
                .
                <p>
                  The student's parent will receive an email to complete intake
                  scheduling.
                </p>
              </div>
            ),
            { duration: 30000 }
          );
          history.push("/school");
        } else if (valuesToPost.schedulingMethod === "pwp_scheduled") {
          toast.success(
            () => (
              <div>
                <p>Successfully referred {organizationMember.firstName}!</p>
                <TextLink href={`/school/students/${organizationMember.id}`}>
                  Click here for more details
                </TextLink>
                .
                <p>
                  The student's parent will receive an email to consent and
                  complete scheduling.
                </p>
              </div>
            ),
            { duration: 30000 }
          );
          history.push("/school");
        } else {
          history.push(
            `/school/scheduling/${organizationMember.id}?partialReferralId=${data.data.id}`
          );
        }
      },
      onSettled: () => formik.setSubmitting(false),
    });
  };
  const combinedDefaultValues = {
    ...defaultValues,
    patientPersonalEmail: organizationMember.studentEmail,
    patientPreferredName: organizationMember.firstName,
  };
  const formik = useFormik<FormValues>({
    // If you're testing this out and are tired of filling the form out every
    // single time, set this localstorage value to prefill with test data.
    // window.localStorage.setItem("prefillTestValues", true)
    initialValues: window.localStorage.getItem("prefillTestValues")
      ? testDefaultValues
      : combinedDefaultValues,
    validationSchema,
    onSubmit: (values) => {
      const insuranceHandlingDirective: PartialReferralPostArguments["insuranceHandlingDirective"] = collectInsuranceInfo
        ? values.hasNoInsurance
          ? "no_insurance"
          : "collect_info"
        : "not_applicable";
      const valuesToPost = {
        ...values,
        insuranceHandlingDirective,
        guardianOkToContact: values.guardianOkToContact === "yes",
        attendanceIssues: values.attendanceIssues === "yes",
        organizationApiKey: myOrg.apiKey,
        organizationMemberId: organizationMember.id,
      };
      analytics.track("SCD:SubmitPartialReferralForm", {
        schedulingMethod: values.schedulingMethod,
        organizationMemberId: organizationMember.id,
      });
      if (valuesToPost.schedulingMethod === "parent_scheduled") {
        confirmParentScheduleModalContext.showModal(() =>
          saveReferral(valuesToPost)
        );
      } else {
        saveReferral(valuesToPost);
      }
    },
  });
  const allFieldsTouched = Object.keys(defaultValues).reduce(
    (acc, key) => acc && !!formik.touched[key as keyof FormValues],
    true
  );
  const validationErrors = Object.values(formik.errors);
  const errorMessage =
    validationErrors.length > 0
      ? validationErrors.join(" ")
      : extractApiErrors(creationError);

  return (
    <>
      <ConfirmParentScheduleModalInsert
        modalContext={confirmParentScheduleModalContext}
      />
      <Form onSubmit={formik.handleSubmit}>
        <FormGroup>
          <FormGroupHeader>Student</FormGroupHeader>
          <FormRowGrid>
            <FieldAndError>
              <TextField
                label="First Name"
                disabled
                value={organizationMember.firstName}
              />
            </FieldAndError>
            <FieldAndError>
              <TextField
                label="Last Name"
                disabled
                value={organizationMember.lastName}
              />
            </FieldAndError>
          </FormRowGrid>
          <FormRowGrid>
            <FieldAndError>
              <TextField
                label="School Email"
                disabled
                value={organizationMember.studentEmail}
              />
            </FieldAndError>
            <FieldAndError>
              <TextField
                label="Preferred Name"
                name="patientPreferredName"
                formik={formik}
              />
              {organizationMember.firstName ===
                formik.values.patientPreferredName && (
                <Info>This student prefers their legal first name.</Info>
              )}
            </FieldAndError>
          </FormRowGrid>
          <FormRowGrid>
            <FieldAndError>
              <TextField
                label="Personal email"
                name="patientPersonalEmail"
                formik={formik}
              />
              {organizationMember.studentEmail ===
                formik.values.patientPersonalEmail && (
                <Info>
                  This email is the same as the student's school email.
                </Info>
              )}
              <FieldError name="patientPersonalEmail" formik={formik} />
            </FieldAndError>
            <FieldAndError>
              <TextField
                label="Phone number"
                name="patientPhoneNumber"
                formik={formik}
              />
              <FieldError name="patientPhoneNumber" formik={formik} />
            </FieldAndError>
          </FormRowGrid>
        </FormGroup>
        <FormGroup>
          <FormGroupHeader>Parent</FormGroupHeader>
          <FormRow>
            <FieldAndError>
              <div>Parent Role</div>
              <RadioButtonGroup
                formik={formik}
                commonRadioButtonProps={{ name: "guardianKind" }}
                radioButtonProps={[
                  { value: "mother", label: "Mother" },
                  { value: "father", label: "Father" },
                  { value: "guardian", label: "Guardian" },
                ]}
              />
              <FieldError name="guardianKind" formik={formik} />
            </FieldAndError>
          </FormRow>
          <FormRowGrid>
            <FieldAndError>
              <TextField
                label="First name"
                name="guardianFirstName"
                formik={formik}
              />
              <FieldError name="guardianFirstName" formik={formik} />
            </FieldAndError>
            <FieldAndError>
              <TextField
                label="Last name"
                name="guardianLastName"
                formik={formik}
              />
              <FieldError name="guardianLastName" formik={formik} />
            </FieldAndError>
          </FormRowGrid>
          <FormRowGrid>
            <FieldAndError>
              <TextField label="Email" name="guardianEmail" formik={formik} />
              <FieldError name="guardianEmail" formik={formik} />
            </FieldAndError>
            <FieldAndError>
              <TextField
                label="Phone number"
                name="guardianPhone"
                formik={formik}
              />
              <FieldError name="guardianPhone" formik={formik} />
            </FieldAndError>
          </FormRowGrid>
          <FormRowGrid>
            <SelectField
              label="Preferred language"
              name="guardianPreferredLanguage"
              formik={formik}
            >
              {Object.entries(languageOptions).map(
                ([language, displayName]) => (
                  <option key={language} value={language}>
                    {displayName}
                  </option>
                )
              )}
            </SelectField>
            {formik.values.guardianPreferredLanguage === "other" ? (
              <TextField
                formik={formik}
                name="guardianPreferredLanguageOther"
                label="Please specify language"
              />
            ) : null}
          </FormRowGrid>
        </FormGroup>
        {collectInsuranceInfo ? (
          <FormGroup>
            <FormGroupHeader>Insurance</FormGroupHeader>
            <FormRow>
              <FieldAndError>
                <InsuranceTooltipAndField>
                  <CheckboxField
                    formik={formik}
                    name="hasNoInsurance"
                    label="I'm certain this student is not covered by insurance."
                  />
                  <InsuranceTooltip>
                    <Tooltip
                      tooltipContents={
                        <InsuranceTooltipContents>
                          Only check this if you are sure this individual is not
                          covered by insurance. If there's any doubt, leave it
                          unchecked.
                        </InsuranceTooltipContents>
                      }
                    >
                      <Icon name="info" title="" />
                    </Tooltip>
                  </InsuranceTooltip>
                </InsuranceTooltipAndField>
                <FieldError name="hasNoInsurance" formik={formik} />
              </FieldAndError>
            </FormRow>
          </FormGroup>
        ) : null}
        <FormGroup>
          <FormGroupHeader>Referring Staff</FormGroupHeader>
          <FormRowGrid>
            <FieldAndError>
              <TextField
                label="Phone number"
                name="staffReferrerPhoneNumber"
                formik={formik}
              />
              <FieldError name="staffReferrerPhoneNumber" formik={formik} />
            </FieldAndError>
          </FormRowGrid>
        </FormGroup>
        <FormGroup>
          <FormGroupHeader>Intake Information</FormGroupHeader>
          <FormRow>
            <FieldAndError>
              <YesNoQuestion>
                <RadioButtonGroup
                  formik={formik}
                  radioButtonProps={[
                    { value: "yes", label: "Yes" },
                    { value: "no", label: "No" },
                  ]}
                  commonRadioButtonProps={{ name: "attendanceIssues" }}
                />
                Is the student being referred known to have attendance issues at
                school?
              </YesNoQuestion>
              <FieldError name="attendanceIssues" formik={formik} />
            </FieldAndError>
          </FormRow>
          <FormRow>
            <FieldAndError>
              <CheckboxField
                formik={formik}
                name="guardianVerbalConsent"
                label="Parent / Caregiver has given verbal consent to treatment and will provide written consent to Daybreak Health by first therapy appointment"
              />
              <FieldError name="guardianVerbalConsent" formik={formik} />
            </FieldAndError>
          </FormRow>
          <FormRow>
            <FieldAndError>
              <YesNoQuestion>
                <RadioButtonGroup
                  formik={formik}
                  radioButtonProps={[
                    { value: "yes", label: "Yes" },
                    { value: "no", label: "No" },
                  ]}
                  commonRadioButtonProps={{
                    name: "guardianOkToContact",
                  }}
                />
                Parent / Caregiver can be contacted by Daybreak Health?
              </YesNoQuestion>
              <FieldError name="guardianOkToContact" formik={formik} />
              <Info>
                Selecting `No` will result in the referred youth self consenting
                to care. Only select `No` if you know that Parent or Caregiver
                involvement will disrupt care. Please provide those concerns
                under `Special Considerations` so that our clinical team may
                validate the need for the referred youth to self consent against
                local requirements.
              </Info>
            </FieldAndError>
          </FormRow>
        </FormGroup>
        <FormGroup>
          <FormRow>
            <FieldAndError>
              <TextAreaField
                formik={formik}
                name="academicBehaviorAndSchoolIssues"
                label="Academic & Behavioral School Issues"
                placeholder="E.g. classroom
                  participation, conduct disorder, etc."
              />
              <FieldError
                name="academicBehaviorAndSchoolIssues"
                formik={formik}
              />
            </FieldAndError>
          </FormRow>
          <FormRow>
            <FieldAndError>
              <TextAreaField
                formik={formik}
                name="counselingGoals"
                label="Problem areas (socio-emotional) & treatment goals."
                placeholder="E.g. reduce anxiety"
              />
              <FieldError name="counselingGoals" formik={formik} />
            </FieldAndError>
          </FormRow>
          <FormRow>
            <FieldAndError>
              <TextAreaField
                formik={formik}
                name="specialConsiderations"
                label="Special considerations"
                placeholder="E.g. issues around parent consent, or privacy at home to have the counseling"
              />
              <FieldError name="specialConsiderations" formik={formik} />
            </FieldAndError>
          </FormRow>
        </FormGroup>
        {errorMessage && allFieldsTouched ? (
          <Errors>{errorMessage}</Errors>
        ) : null}
        <Buttons>
          <TealButton
            variant="secondary"
            onClick={() => {
              formik.setFieldValue("schedulingMethod", "pwp_scheduled");
              formik.submitForm();
            }}
            disabled={formik.isSubmitting}
          >
            Submit referral
          </TealButton>
        </Buttons>
      </Form>
    </>
  );
};

export default PartialReferralForm;
