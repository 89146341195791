// A react component for a page that fetches PWP data from the backend

import { Formik } from "formik";
import { useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { Trans } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { ParentWelcomePacket } from "../../api/queries/parentWelcomePackets";
import { useDaybreakTranslation } from "../../hooks/useDaybreakTranslation";
import ParentWelcomePacketLayout from "../../layouts/ParentWelcomePacketLayout/ParentWelcomePacketLayout";
import {
  ButtonGroup,
  Container,
  Description,
  H1,
  HeaderSection,
  LanguageButton,
  LanguageSwitchButton,
  LegalSection,
} from "./elements";
import en from "./ParentWelcomePacket.en.json";
import es from "./ParentWelcomePacket.es.json";
import * as Yup from "yup";
import { useModal } from "../../components/Modals/BaseModal/BaseModal";
import { isDaybreakErrorV4 } from "../../api/apiClient";
import CompleteIntakeSection from "./CompleteIntakeSection";
import IntakeCompletedSection from "./IntakeCompletedSection";
import AcuityScheduler from "../../components/AcuityScheduler/AcuityScheduler";
import IdentityConfirmation from "./components/IdentityConfirmation";
import SubmitInsurance, {
  OrganizationSlugType,
} from "./components/SubmitInsurance";
import ConsentAndSignature from "./components/ConsentAndSignature";
import useInsuranceResourceHooks, {
  Insurance,
} from "../../api/queries/insurance";
import useWelcomePacketResourceHooks from "../../api/queries/welcomePacket";
import { careCoordinatorEmail } from "../../constants";
import {
  trackEvent,
  identifyAnonymousUser,
  trackPage,
} from "client/amplitudeHelper";

const validationSchema = Yup.object().shape({
  agreementTosPp: Yup.boolean()
    .nullable()
    .oneOf([true], "You must agree to the terms of use and privacy policy."),
  agreementIc: Yup.boolean()
    .nullable()
    .oneOf([true], "You must agree to the informed consent."),
  agreementAobHipaa: Yup.boolean()
    .nullable()
    .oneOf(
      [true],
      "You must agree to the assignment of benefits authorization for payers."
    ),
  agreementHipaaRoi: Yup.boolean()
    .nullable()
    .oneOf([true], "You must agree to the release of information."),
  agreementSignature: Yup.string()
    .nullable()
    .required("Signature is required."),
});

export type WelcomePacketFormProps = {
  packet: ParentWelcomePacket;
  updatePacket: (data: ParentWelcomePacket) => void;
  createInsurance: (data: Insurance) => void;
  reloadPacket: () => Promise<unknown>;
  wrongIdentityRedirectPath: string;
  identityPreConfirmed?: boolean;
};

export const WelcomePacketForm = ({
  packet,
  updatePacket,
  createInsurance,
  reloadPacket,
  wrongIdentityRedirectPath,
  identityPreConfirmed = false,
}: WelcomePacketFormProps): JSX.Element => {
  const history = useHistory();

  const [identityConfirmed, setIdentityConfirmed] = useState<boolean>(
    identityPreConfirmed
  );

  const [insuranceLoading, setInsuranceLoading] = useState<boolean>(
    window.localStorage.getItem("insuranceLoading") === "true"
  );

  useEffect(() => {
    if (insuranceLoading) {
      // Someone will get impatient and reload the page while we're still waiting for the background
      // processes to do their thing.
      window.localStorage.setItem("insuranceLoading", "true");
      setTimeout(() => {
        reloadPacket().then(() => {
          window.localStorage.setItem("insuranceLoading", "false");
          setInsuranceLoading(false);
        });
      }, 20 * 1000);
    } else {
      window.localStorage.removeItem("insuranceLoading");
    }
  }, [reloadPacket, insuranceLoading]);

  const onSubmitInsurance = (data: Insurance) => {
    createInsurance(data);
    setInsuranceLoading(true);
  };

  const schedulingModal = useModal({
    onOpen: () => trackEvent("PWP:openedSchedulingModal"),
    onClose: () => trackEvent("PWP:closedSchedulingModal"),
  });

  const { t, i18n } = useDaybreakTranslation("pwpForm", { en, es });

  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language);
  };

  const agreementText = useMemo(() => {
    return t("legalText", {
      schoolName: packet?.organizationName,
      careCoordinatorEmail,
    });
  }, [packet, t]);

  return (
    <>
      {packet.schedulingLink &&
        schedulingModal.asModal(
          <AcuityScheduler
            url={packet.schedulingLink}
            onSchedule={() => {
              trackEvent("PWP:intakeScheduled");
              setTimeout(() => {
                reloadPacket();
              }, 120 * 1000);
            }}
          />
        )}

      <Formik
        initialValues={{
          ...packet,
          agreementSignature: "",
          agreementText: agreementText,
        }}
        validationSchema={validationSchema}
        onSubmit={updatePacket}
      >
        {({ isSubmitting, errors, touched, handleSubmit }) => (
          <Container>
            <HeaderSection>
              <H1>{t("consentTitle")}</H1>
              <LanguageSwitchButton>
                <ButtonGroup>
                  <LanguageButton
                    variant="secondary"
                    isActive={i18n.language === "en"}
                    onClick={() => changeLanguage("en")}
                  >
                    English
                  </LanguageButton>
                  <LanguageButton
                    variant="secondary"
                    isActive={i18n.language === "es"}
                    onClick={() => changeLanguage("es")}
                  >
                    Español
                  </LanguageButton>
                </ButtonGroup>
              </LanguageSwitchButton>

              <Description>
                <Trans components={{ p: <p />, b: <b />, i: <i />, a: <a /> }}>
                  {t("descriptionText")}
                </Trans>
              </Description>
            </HeaderSection>

            <IdentityConfirmation
              t={t}
              isConfirmed={identityConfirmed}
              kiddoName={`${packet.patientFirstName} ${packet.patientLastName}`}
              parentName={`${packet.guardianFirstName} ${packet.guardianLastName}`}
              onConfirmed={() => setIdentityConfirmed(true)}
              onDenied={() => {
                toast.error(
                  "Apologies, but we'll need to start from scratch..."
                );

                history.push(wrongIdentityRedirectPath);
              }}
            />

            {identityConfirmed && (
              <>
                {packet.agreementSignature ? (
                  <LegalSection>
                    <p>
                      {t("parentalConsentGiven", {
                        parentName: `${packet.guardianFirstName} ${packet.guardianLastName}`,
                        date: packet.completedDate?.split("T")[0],
                      })}
                    </p>
                  </LegalSection>
                ) : (
                  <ConsentAndSignature
                    t={t}
                    agreementText={agreementText}
                    errors={errors}
                    touched={touched}
                    isSubmitting={isSubmitting}
                    handleSubmit={handleSubmit}
                  />
                )}

                {packet.collectInsurance && (
                  <SubmitInsurance
                    t={t}
                    isLoading={insuranceLoading}
                    createInsurance={onSubmitInsurance}
                    organizationSlug={
                      (
                        packet.organizationSlug ?? ""
                      ).toLowerCase() as OrganizationSlugType
                    }
                  />
                )}
                {packet.asyncIntakeLink &&
                  !packet.asyncIntakeQuestionnaireCompleted && (
                    <CompleteIntakeSection link={packet.asyncIntakeLink} />
                  )}
                {packet.asyncIntakeQuestionnaireCompleted && (
                  <IntakeCompletedSection />
                )}
              </>
            )}
          </Container>
        )}
      </Formik>
    </>
  );
};

const WelcomePacketPage = (): JSX.Element => {
  const { sfid, organizationApiKey = "web-referral" } = useParams<{
    sfid: string;
    organizationApiKey: string;
  }>();

  const {
    useGet: useGetPWP,
    useUpdate: useUpdatePWP,
  } = useWelcomePacketResourceHooks();

  const { data: parentWelcomePacket, isLoading, refetch } = useGetPWP(
    `by_sfid/${sfid}`
  );

  const { mutate: updatePWP } = useUpdatePWP(`by_sfid/${sfid}`, {
    onSuccess: () => {
      trackEvent("PWP:SUCCESS:PATCH");
      refetch();
      toast.success("Thank you!");
    },
    onError: (response) => {
      trackEvent("PWP:ERROR:PATCH", response);
      if (isDaybreakErrorV4(response)) {
        toast.error(response.error);
      }
    },
  });

  const {
    mutate: createInsuranceHook,
  } = useInsuranceResourceHooks().useCreate();

  const createInsurance = (data: Insurance) => {
    if (!parentWelcomePacket) return;

    data.clientSfid = parentWelcomePacket.data.clientSfid;
    data.clientReferralSfid = parentWelcomePacket.data.clientReferralSfid;

    trackEvent("PWP:Insurance:POST");
    createInsuranceHook(data);
  };

  // Only runs once, when the component mounts
  useEffect(() => {
    trackPage("PWP:UniqueLinkPage");
  }, []);

  useEffect(() => {
    isLoading && trackEvent("PWP:loadingPWP");
  }, [isLoading]);

  useEffect(() => {
    if (parentWelcomePacket) {
      identifyAnonymousUser({
        firstName: parentWelcomePacket.data.guardianFirstName,
        lastName: parentWelcomePacket.data.guardianLastName,
      });

      parentWelcomePacket.data.schedulingLink &&
        trackEvent("PWP:showSchedulingButton");
      parentWelcomePacket.data.insuranceLink &&
        trackEvent("PWP:showInsuranceButton");
      parentWelcomePacket.data.packetComplete &&
        trackEvent("PWP:pwpCompletedPage");
    }
  }, [parentWelcomePacket]);

  useEffect(() => {
    if (
      parentWelcomePacket &&
      parentWelcomePacket.data.agreementSignature &&
      !parentWelcomePacket.data.schedulingLink &&
      !parentWelcomePacket.data.insuranceLink
    ) {
      toast.success("All done - Thank you!");
      trackEvent("PWP:Complete");
    }
  }, [parentWelcomePacket]);

  const wrongIdentityRedirectPath = useMemo(
    () =>
      (parentWelcomePacket?.data.organizationSlug &&
        `/school/${parentWelcomePacket.data.organizationSlug}/packet`) ||
      (organizationApiKey && `/school/${organizationApiKey}/packet`) ||
      "/parent-packet",
    [parentWelcomePacket, organizationApiKey]
  );

  return (
    <ParentWelcomePacketLayout loading={isLoading}>
      {parentWelcomePacket && (
        <WelcomePacketForm
          reloadPacket={refetch}
          packet={parentWelcomePacket.data}
          updatePacket={updatePWP}
          createInsurance={createInsurance}
          wrongIdentityRedirectPath={wrongIdentityRedirectPath}
        />
      )}
    </ParentWelcomePacketLayout>
  );
};

export default WelcomePacketPage;
